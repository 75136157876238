import { Tab } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { FC, Fragment, useEffect, useState } from "react";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import { GuestsObject } from "components/HeroSearchForm/type";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalSelectGuests from "components/ModalSelectGuests";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import GuestsInput from "./../../components/HeroSearchForm2Mobile/GuestsInput";
import { EventFormQuestionType, EventPriceDataType } from "data/types";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useParams } from "react-router-dom";
import Select from "shared/Select/Select";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Alert from '@mui/material/Alert';
import ButtonCircle from "shared/Button/ButtonCircle";
import { eventGoAddToCartAndPay, eventGoFormSubmission, eventGoFreeItemsCheckout } from "hooks/EventGoAPI";
import { auth } from "firebaseSetup";


export interface CheckOutPagePageMainProps {
  className?: string;
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = "",
}) => {

  const {id, viewId} = useParams()

  const [eventPrices, setEventPrices] = useState<EventPriceDataType[]>([])
  const [orders, setOrders] = useState<{[key: string]: EventPriceDataType}>({})
  const [orderTotal, setOrderTotal] = useState<number>(0)
  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    emailRepeat: '',
    userUID: '',
  })

  const [userCoupon, setUserCoupon] = useState({
    coupon: '',
    couponType: '',
    promotionCode: '',
    amount: 0,
  })





  let [eventDetails, setEventDetails] = useState({
    title: "",
    description: "",
    photoURL: "",
    location: "",
    orgId: "",
    startDateString: "",
    endDateString: "",
    options: {},
    address: {},
  })
  let [formQuestions, setFormQuestions] = useState<EventFormQuestionType[]>([])
  let [success, setSuccess] = useState<string>("")
  const [paymentLoading, setPaymentLoading] = useState<boolean>(false)

  async function eventPromoValidation(couponCode:string){
    const dataBody = `coupon=${couponCode}&event_id=${id || ''}`;
    fetch("https://v1eventsapiapplycoupon-650318705789.us-central1.run.app?"+dataBody,{
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*', }
    }).then((response) => {
      response.json().then((data) => {
        var dataS = {
          success: false, 
          couponType: "",
          promotionCode: '',
          couponCode: '',
          amount: 0}
        dataS = data
        if (dataS.success){
          if (dataS.couponType == 'FREE'){
            //FREE Order
            setUserCoupon({
              coupon: couponCode,
              couponType: dataS.couponType,
              promotionCode: '',
              amount: 0,
            })
            window.alert('Coupon is now applied: Free Order')

          }
          else if (dataS.couponType == "PERCENTAGE"){
            setUserCoupon({
              coupon: couponCode,
              couponType: dataS.couponType,
              promotionCode: dataS.promotionCode,
              amount: dataS.amount,
            })
            window.alert(`Coupon is now applied: ${dataS.amount}% OFF from your total order`)
            // if (totalAmount > 0){
            //   let totalDiscountP = Math.round(totalAmount * (dataS.amount / 100))
            //   setDiscountAmount(totalDiscountP)
            //   setDiscountTotal(totalAmount - totalDiscountP)
            // }
            
            // calculateCartAmount()
          }
          else if (dataS.couponType == "AMOUNT"){
            setUserCoupon({
              coupon: couponCode,
              couponType: dataS.couponType,
              promotionCode: dataS.promotionCode,
              amount: dataS.amount,
            })
            // if (totalAmount > 0 && totalAmount > dataS.amount){
            //   setDiscountAmount(dataS.amount)
            //   setDiscountTotal(totalAmount - dataS.amount)
            // }
            // window.alert(`Coupon is now applied: $${dataS.amount} OFF from your total order`)
            // calculateCartAmount()
          }
          else{
            setUserCoupon({
              coupon: "",
              couponType: "",
              promotionCode: "",
              amount: 0,
            })
            window.alert('Coupon is invalid')
          }
        }
        else{
          setUserCoupon({
            coupon: "",
            couponType: "",
            promotionCode: "",
            amount: 0,
          })
          window.alert('Coupon is invalid')
        }
      })
    })
  }

  

  useEffect(()=>{

    //get id
    const viewLoginRedirect = localStorage.getItem("login_redirect")
    if (viewLoginRedirect != null && viewLoginRedirect != ""){
      localStorage.removeItem('login_redirect')
    }
    const viewD = localStorage.getItem("eventView")
    if (viewD != null && viewD != ""){
      let parseData = JSON.parse((viewD || "{}"))
      let eId = ""
      let vId = ""
      if (parseData.eventId != null){
        eId = parseData.eventId
      }
      if (parseData.viewId != null){
        vId = parseData.viewId
      }
      
      //match
      if (eId == (id || "") && vId == (viewId || "")){
        let couponC = ""
        if (parseData.coupon != null){
          //apply
          couponC = parseData.coupon
        }
        fetchEventDetails(couponC)
      }
      else{
        window.location.assign("/e/"+(id || ""))
      }
    }
    else{
      window.location.assign("/e/"+(id || ""))
    }
  },[])

  function fetchEventDetails(coupon:string){
    fetch("https://us-central1-blink-574af.cloudfunctions.net/v1EventsAPIGetTicketData?event_id="+(id || "")+"&view_id="+(viewId || ""),{
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      const responseTask = response.json();
      responseTask.then((data) => {
        setOrderTotal(0)
        if (data.event != null){
          setEventDetails(data.event)
        }
        if (data.form != null){
          if (data.form.data != null){
            setFormQuestions(data.form.data)
          }
        }
        if (data.tickets != null){
          let pricesArray: EventPriceDataType[] = []
          pricesArray = Object.values(data.tickets)
          pricesArray = pricesArray.filter((priceItm)=>{
            let endSeconds = priceItm.saleEnd._seconds             
            let endD = new Date(0)
            endD.setUTCSeconds(endSeconds)
            let today = new Date()
            if (endD > today){
              return true
            }
            else{
              return false
            }
          })
          pricesArray.sort((a,b)=>{
            return a.order - b.order
          })
          setEventPrices(pricesArray)

          if (coupon != ""){
            eventPromoValidation(coupon)
          }

          //setup profile
          if (auth.currentUser != null){
            let userEmail = ""
            let userFN = ""
            let userLN = ""
            userEmail = auth.currentUser.email || ""
            
            if (userEmail != ""){
              let usr = localStorage.getItem('user') || "{}"
              if (usr == '{}'){}
              else{
                let userObj = JSON.parse(usr)
                if (userObj.user != null){
                  if (userObj.user.firstName != null){
                    userFN = userObj.user.firstName
                  }
                  if (userObj.user.lastName != null){
                    userLN = userObj.user.lastName
                  }
                }
              }
              setUserInfo({
                ...userInfo,
                emailAddress: userEmail,
                emailRepeat: userEmail,
                firstName: userFN,
                lastName: userLN,
              })
            }
          }

        }
      })
    })
  }

  async function confirmAndPay(){
    setPaymentLoading(true)

    if (
      userInfo.firstName != "" && 
      userInfo.lastName != "" && 
      userInfo.emailAddress != "" && 
      userInfo.emailAddress == userInfo.emailRepeat && 
      userInfo.emailAddress.includes('@')){

      console.log('information correct: ' + userInfo)
      //check to see if need to submit
    let formSubmissionId = ""
    let formSubmissionItem: any[] = []
    let formSubmissionFilledAll = true
    formQuestions.forEach((formItm)=>{
      //if one question that is required and its not filled yet, dont submit
      let formValue = ""
      if (formItm.value != null){
        if (formItm.value != ""){
          formValue = formItm.value
        }
      }
      if (formItm.required && formValue == ""){
        formSubmissionFilledAll = false
      }
      formSubmissionItem.push({
        id: formItm.id,
        title: formItm.title,
        value: formValue,
      })
    })
    console.log(formQuestions)
    if (formQuestions.length == 0){
      formSubmissionFilledAll = true
    }
    //
    if (formSubmissionFilledAll){
      const sId = await eventGoFormSubmission(
        id || '',
        userInfo.emailAddress,
        userInfo.firstName,
        userInfo.lastName,
        formSubmissionItem,
      )
      formSubmissionId = sId

      //continue add to cart
      //check shopping cart
    let cartItems: any[] = []
    Object.values(orders).forEach((orderItm) => {
      if (orderItm.quantity > 0){
        cartItems.push({...orderItm, cartItemId: orderItm.id})
      }
    })
    const eveId = id || ''
    const uniqueId = viewId || ''

    if (eveId != '' && uniqueId != '' && cartItems.length > 0){
      //add to cart and pay
      eventGoAddToCartAndPay(
        eveId,
        uniqueId,
        userInfo.firstName,
        userInfo.lastName,
        userInfo.emailAddress,
        eventDetails.title,
        eventDetails.photoURL,
        eventDetails.orgId,
        userCoupon.coupon,
        userCoupon.promotionCode,
        userCoupon.couponType,
        formSubmissionId,
        cartItems)
    }
    else{
      setPaymentLoading(false)
      alert('Please add items to the cart before you continue')
      setSuccess("No items in cart")
    }
    
    }
    else{
      setPaymentLoading(false)
      alert("Please fill all required questions")
      setSuccess("Please fill all required questions")
    }

    }
    else{
      //information not correct
      setPaymentLoading(false)
      alert("You have to fill contact information")
      setSuccess("You have to fill contact information")
    }
    
  }

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <img
                alt=""
                className="absolute inset-0 object-cover"
                sizes="200px"
                src={eventDetails.photoURL}
              />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {eventDetails.location}
              </span>
              <span className="text-base font-medium mt-1 block">
                {eventDetails.title}
              </span>
            </div>
            <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
              {eventDetails.startDateString}
            </span>
            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Order Summary</h3>
          {
            Object.values(orders).filter((orderItm) => {
              return orderItm.quantity > 0
            }).map((orderItm) => {
              return <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>{orderItm.priceDescription} X{orderItm.quantity}</span>
              <span>{orderItm.price.toLocaleString('en-US',{style: 'currency', currency: orderItm.priceCurrency})}</span>
            </div>
            })
          }
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          {userCoupon.coupon != "" &&
          <div>
          <div className="flex justify-between font-semibold">
          <span>Coupon Applied</span>
          <span>{userCoupon.coupon}</span>
          </div>
          <div className="flex justify-between font-semibold">
          <span>Coupon Type</span>
          <span>{userCoupon.couponType == "FREE" ? "Free Order" : userCoupon.couponType == "AMOUNT" ? "Discount Amount " + userCoupon.amount.toLocaleString('en-US',{style: 'currency', currency: 'CAD'}) : "Discount Percentage " + userCoupon.amount + "%" }</span>
          </div>
        </div>
        }
          <div className="flex justify-between font-semibold">
            <span>Sub-Total</span>
            <span>{orderTotal.toLocaleString("en-US",{style: 'currency', currency: 'CAD'})}</span>
          </div>
        </div>
      </div>
    );
  };

  const [couponApplied, setCouponApplied] = useState('')
  const renderCouponButton = () => {
    return (
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
      <div className="mt-10 mb-5 relative max-w-sm">
      <form>
        <Input
          required
          aria-required
          placeholder="Enter coupon code"
          type="text"
          rounded="rounded-full"
          value={couponApplied}
          onChange={(e)=>{
            let coupon = e.target.value
            setCouponApplied(coupon.toUpperCase())
          }}
        />
      </form>
      <ButtonCircle
            className="absolute transform top-1/2 -translate-y-1/2 right-[5px]"
            onClick={()=>{
              if (couponApplied != ""){
                eventPromoValidation(couponApplied)
              }
            }}
          >
            <i className="las la-arrow-right text-xl"></i>
          </ButtonCircle>
      </div>
      </div>
    )
  }

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Confirm and payment
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <h3 className="text-2xl font-semibold">Your Event</h3>
            <NcModal
              renderTrigger={(openModal) => (
                <span
                  onClick={() => openModal()}
                  className="block lg:hidden underline  mt-1 cursor-pointer"
                >
                  View event details
                </span>
              )}
              renderContent={renderSidebar}
              modalTitle="Event details"
            />
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
            <ModalSelectDate
              renderChildren={({ openModal }) => (
                <button
                  className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                  type="button"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Date</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      {eventDetails.startDateString}
                    </span>
                  </div>
                  <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" />
                </button>
              )}
            />

            <ModalSelectGuests
              renderChildren={({ openModal }) => (
                <button
                  type="button"
                  className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Location</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      <span className="line-clamp-1">
                        {eventDetails.location}
                      </span>
                    </span>
                  </div>
                  <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" />
                </button>
              )}
            />

          </div>
        </div>

        <div>
        <h3 className="text-2xl font-semibold">Select Tickets</h3>
        {success && <Alert severity="success">
          {success}
        </Alert>}
        { eventPrices?.map((priceItm) => {
          return <NcInputNumber
          className="w-full mt-6"
          defaultValue={0}
          onChange={(value) => {
            let orderItems = orders
            if (Object.keys(orderItems).includes(priceItm.id)){
              let orderD = orderItems[priceItm.id]
              orderD.quantity = value
              orderD.priceItemId = priceItm.id
              orderItems[priceItm.id] = orderD
            }
            else{
              let orderD = priceItm
              orderD.quantity = value
              orderD.priceItemId = priceItm.id
              orderItems[priceItm.id] = orderD
            }
            let orderT = 0
            Object.values(orderItems).forEach((itmVal) => {
              orderT += itmVal.price * itmVal.quantity
            })
            setOrders(orderItems)
            setOrderTotal(orderT)
            setSuccess(`${priceItm.priceDescription} X ${priceItm.quantity} added to cart`)
          }}
          max={priceItm.max}
          min={0}
          label={priceItm.price.toLocaleString('en-US',{style: 'currency', currency: priceItm.priceCurrency})}
          desc={priceItm.priceDescription}
        />
        })}
          
          </div>
          

        <div className="mt-5">
          
          <h3 className="text-2xl font-semibold">Contact Information</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

          <div className="mt-6">
            
            <div className="flex space-x-5  ">
                    <div className="flex-1 space-y-1 mt-2">
                      <Label>First Name *</Label>
                      <Input type="text" defaultValue={userInfo.firstName} onChange={(e) => {
                        const fn = e.currentTarget.value
                        let uInfo = userInfo
                        uInfo.firstName = fn
                        setUserInfo(uInfo)
                      }} />
                    </div>
                    <div className="flex-1 space-y-1 mt-2">
                      <Label>Last Name *</Label>
                      <Input type="text" defaultValue={userInfo.lastName} onChange={(e) => {
                        const fn = e.currentTarget.value
                        let uInfo = userInfo
                        uInfo.lastName = fn
                        setUserInfo(uInfo)
                      }} />
                    </div>
                  </div>
                  <div className="space-y-1 mt-2">
                    <Label>Email Address *</Label>
                    <Input type="email" defaultValue={userInfo.emailAddress} onChange={(e) => {
                        const fn = e.currentTarget.value
                        let uInfo = userInfo
                        uInfo.emailAddress = fn
                        setUserInfo(uInfo)
                      }}/>
                  </div>
                  <div className="space-y-1 mt-2">
                    <Label>Confirm Email Address *</Label>
                    <Input type="email" defaultValue={userInfo.emailAddress} onChange={(e) => {
                        const fn = e.currentTarget.value
                        let uInfo = userInfo
                        uInfo.emailRepeat = fn
                        setUserInfo(uInfo)
                      }}/>
                  </div>
                  <div className="space-y-1 mt-2">
                    <h3 className="text-1xl font-semibold" hidden={formQuestions.length == 0}>Additional Questions</h3>
                  </div>
                  <div className="mt-6">
                  {
                    formQuestions.map((ques) => {
                      if (ques.optionType == "options"){
                        //select
                        return <div className="space-y-1 mt-2">
                          <Label>{ques.order+1}. {ques.title} {ques.required ? '*' : ''}</Label>
                          <Select onChange={(e)=>{
                            let allQues = formQuestions
                            let cQues = ques
                            cQues.value = e.target.value || ""
                            allQues[ques.order] = cQues
                            setFormQuestions(allQues)
                          }}>
                          <option value={''}>{'--- select option ---'}</option>
                            {
                              ques.options.map((itm)=>{
                                return <option value={itm}>{itm}</option>
                              })
                            }
                          </Select>
                          </div>
                      }
                      else if (ques.optionType == "text"){
                        if (ques.valueType == "phone"){
                          return <div className="space-y-1 mt-2">
                          <Label>{ques.order+1}. {ques.title} {ques.required ? '*' : ''}</Label>
                          <PhoneInput
                                  placeholder={ques.title}
                                  value={""}
                                  defaultCountry="CA"
                                  onChange={(e) => {
                                    let allQues = formQuestions
                                    let cQues = ques
                                    cQues.value = e || ""
                                    allQues[ques.order] = cQues
                                    setFormQuestions(allQues)
                                  }}/>
                          </div>
                        }
                        else{
                          return <div className="space-y-1 mt-2">
                          <Label>{ques.order+1}. {ques.title} {ques.required ? '*' : ''}</Label>
                          <Input type={'text'} defaultValue="" onChange={(e)=>{
                            let allQues = formQuestions
                            let cQues = ques
                            cQues.value = e.currentTarget.value
                            allQues[ques.order] = cQues
                            setFormQuestions(allQues)
                          }}/>
                          </div>
                        }
                        
                      }
                      else if (ques.optionType == "file"){
                        return <div className="space-y-1 mt-2">
                          <Label>{ques.order+1}. {ques.title} {ques.required ? '*' : ''}</Label>
                          <Input type="file" defaultValue="" />
                          </div>
                      }
                      else if (ques.optionType == "textarea"){
                        return <div className="space-y-1 mt-2">
                          <Label>{ques.order+1}. {ques.title} {ques.required ? '*' : ''}</Label>
                          <Textarea onChange={(e)=>{
                            let allQues = formQuestions
                            let cQues = ques
                            cQues.value = e.currentTarget.value
                            allQues[ques.order] = cQues
                            setFormQuestions(allQues)
                          }}
                          />
                          </div>
                      }
                    })
                  }
                  </div>
            
            <div className="pt-8">
            {userCoupon.coupon != "" &&
          <div className="mt-8">
          <div className="flex justify-between font-semibold">
          <span>Coupon Applied</span>
          <span>{userCoupon.coupon}</span>
          </div>
          <div className="flex justify-between font-semibold">
          <span>Coupon Type</span>
          <span className="color-red">{userCoupon.couponType == "FREE" ? "Free Order" : userCoupon.couponType == "AMOUNT" ? "Discount Amount " + userCoupon.amount.toLocaleString('en-US',{style: 'currency', currency: 'CAD'}) : "Discount Percentage " + userCoupon.amount + "%" }</span>
          </div>
        </div>
        }
        <div className="flex justify-between font-semibold mt-3">
            <span>Sub-Total</span>
            <span>{orderTotal.toLocaleString("en-US",{style: 'currency', currency: 'CAD'})}</span>
        </div>
              {renderCouponButton()}
              <ButtonPrimary 
                className="mt-1"
                loading={paymentLoading}
                disabled={paymentLoading}
                onClick={()=>{
                if (!paymentLoading){
                  confirmAndPay()
                }
              }}>Confirm and pay</ButtonPrimary>

          
          

            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default CheckOutPagePageMain;
